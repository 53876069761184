import React from "react";
import { RecoilRoot } from "recoil";
import { GlobalNavigationBar } from "../components/GlobalNavigationBar";
import { css } from "@emotion/react";

import heroBg from "../images/hero-bg.jpg";
import { SectionStyle } from "../styles/common";

import pic1 from "../images/2022pic/2022pic1.jpg";
import pic2 from "../images/2022pic/2022pic2.jpg";
import pic3 from "../images/2022pic/2022pic3.jpg";

import levelsSm from "../images/sponsor/levels_sm.png";
import levelsLg from "../images/sponsor/levels_lg.png";
import scheduleSm from "../images/sponsor/schedule_sm.png";
import scheduleLg from "../images/sponsor/schedule_lg.png";
import SiteHead from "../head";

const heroSectionStyle = css`
  background: url(${heroBg}) center;
  background-size: cover;
  text-align: center;
  padding: 15.5rem 0;

  &.notice-box {
    h2 {
      margin: 0 0 2.5rem 0;
      font-size: 3rem;
      font-weight: 800;
      line-height: 1.625;

      @media (max-width: 768px) {
        font-size: 1.625rem;
      }
    }

    p {
      font-size: 1.375rem;
      font-weight: 100;
      line-height: 1.636;
    }
  }

  @media (max-width: 768px) {
    padding: 11.25rem 0;
  }
`;
const HeroSection = () => {
  return (
    <div css={heroSectionStyle} className="notice-box">
      <h2>
        <span className="text-primary-sub">2ND EXCELCON</span>의
        <br />
        후원사를 모집합니다!
      </h2>
      <p>
        트위터 Software Maker들의
        <br />
        화제의 네트워킹 행사 EXCELCON과 함께하세요.
      </p>
    </div>
  );
};

const noticeSectionStyle = css`
  ${SectionStyle}
  background: #222222;

  .container {
    max-width: 1024px;

    @media (max-width: 768px) {
      padding-left: 4rem;
      padding-right: 4rem;
    }
  }

  h3 {
    font-size: 3rem;
    margin-bottom: 8.3125rem;

    @media (max-width: 768px) {
      font-size: 1.5rem;
      margin-bottom: 2.4375rem;
    }
  }

  p {
    margin-bottom: 1.818em;
    font-size: 1.375rem;
    font-weight: 100;
    line-height: 1.818;

    @media (max-width: 768px) {
      font-size: 1rem;
    }
  }
`;

const InquiryButtonStyle = css`
  display: inline-block;
  margin-top: 7.5rem;
  padding: 1rem 2rem;
  border-radius: 99rem;
  border: none;
  background: var(--color-primary);
  box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.5);
  font-size: 1.25rem;
  line-height: 1;
  text-decoration: none;
  color: white;

  &::visited {
    color: inherit;
    text-decoration: none;
  }

  @media (max-width: 768px) {
    font-size: 1.125rem;
    padding: 0.375rem 1.125rem;
    margin-top: 3.5625rem;
  }
`;

const NoticeSection = () => {
  return (
    <div css={noticeSectionStyle}>
      <div className="container">
        <h3>2ND EXCELCON 스폰서 모집</h3>
        <p>2ND EXCELCON은 기업 스폰서를 기다리고 있습니다.</p>

        <p>
          EXCELCON은 3층 규모의 마장동 스테이크 하우스 '엑셀플레이스'에서 열리는
          네트워킹 행사입니다. 2022년 1ST EXCELCON은 100여 명의 개발자분들이
          참여하였으며, 개발 분야 세션 발표, 네트워킹 이벤트, 럭키드로우 등의
          구성으로 성황리에 진행되었습니다. 행사를 만들어가는 과정부터 참가
          후기까지 트위터에서 여러 차례 바이럴 되었으며, 트위터 내에서 활발히
          교류하는 분들이 참여하여 큰 화제가 되었던 행사입니다.
        </p>

        <p>
          2023년의 두 번째 EXCELCON은 '소프트웨어 제품을 만드는 사람들'을 위한
          네트워킹 행사로, IT업계의 개발자, 기획자, 디자이너, 마케터 등 다양한
          직군의 'Software Maker'들이 참석하여 경험을 공유하고 네트워킹을 하는
          자리가 될 예정입니다. 참여자분들께서 더욱 알차고 즐겁게 네트워킹과
          정보교류를 즐기실 수 있는 행사가 될 수 있도록 2ND EXCELCON을 함께
          만들어주실 기업 스폰서를 모집합니다.
        </p>

        <a
          css={InquiryButtonStyle}
          href="https://drive.google.com/file/d/1hT5m1l9LY0VQvrkSGT8TLcIRI6jBhAFN/view?usp=share_link"
          target="_blank"
          rel="noopener noreferrer"
        >
          제안서 보기
        </a>
      </div>
    </div>
  );
};

const previousSectionStyle = css`
  ${SectionStyle};
  background: #191919;

  h3 {
    text-align: center;
    font-size: 3rem;
    margin-bottom: 8.4375rem;

    @media (max-width: 768px) {
      font-size: 1.5rem;
      margin-bottom: 1.375rem;
    }
  }

  .images {
    max-width: 74.1875rem;
    margin-left: auto;
    margin-right: auto;

    img {
      max-width: 100%;
      padding: 0;
      border-radius: 0.5rem;
      box-shadow: 10px 10px 50px 0 rgba(0, 0, 0, 0.1);
    }

    @media (max-width: 768px) {
      padding: 0 4rem;
    }
  }
`;

const PreviousSection = () => {
  return (
    <div css={previousSectionStyle}>
      <h3>2022 1st EXCELCON</h3>
      <div className="images">
        <img src={pic1} alt="1st EXCELCON" />
        <img src={pic2} alt="1st EXCELCON" />
        <img src={pic3} alt="1st EXCELCON" />
      </div>
    </div>
  );
};

const levelsSectionStyle = css`
  ${SectionStyle};
  background: #222222;

  h3 {
    text-align: center;
    font-size: 3rem;
    margin-bottom: 10rem;

    @media (max-width: 768px) {
      font-size: 1.5rem;
      margin-bottom: 1.375rem;
    }
  }

  .container {
    @media (max-width: 768px) {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
    }
  }

  .img-sm {
    display: none;
    width: 100%;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 768px) {
      display: block;
    }
  }

  .img-lg {
    display: block;
    width: 100%;
    max-width: 1579px;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 768px) {
      display: none;
    }
  }
`;

const LevelsSection = () => {
  return (
    <div css={levelsSectionStyle}>
      <div className="container">
        <h3>스폰서 등급 안내</h3>
        <img src={levelsSm} className="img-sm" />
        <img src={levelsLg} className="img-lg" />
      </div>
    </div>
  );
};

const scheduleSectionStyle = css`
  ${SectionStyle};
  background: #191919;

  h3 {
    text-align: center;
    font-size: 3rem;
    margin-bottom: 13.375rem;

    @media (max-width: 768px) {
      font-size: 1.5rem;
      margin-bottom: 1.375rem;
    }
  }

  .img-sm {
    display: none;
    width: 100%;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 768px) {
      display: block;
    }
  }

  .img-lg {
    display: block;
    width: 100%;
    max-width: 1345px;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 768px) {
      display: none;
    }
  }
`;

const ScheduleSection = () => {
  return (
    <div css={scheduleSectionStyle}>
      <div className="container text-center">
        <h3>스폰서 모집 일정 안내</h3>
        <img src={scheduleSm} className="img-sm" />
        <img src={scheduleLg} className="img-lg" />
        <a
          css={InquiryButtonStyle}
          href="https://drive.google.com/file/d/1hT5m1l9LY0VQvrkSGT8TLcIRI6jBhAFN/view?usp=share_link"
          target="_blank"
          rel="noopener noreferrer"
        >
          제안서 보기
        </a>
      </div>
    </div>
  );
};

export const Head = () => (
  <SiteHead meta={{ title: "스폰서 모집 | EXCELCON" }} />
);

const SponsorPage = () => {
  return (
    <RecoilRoot>
      <div>
        <GlobalNavigationBar />
        <HeroSection />
        <NoticeSection />
        <PreviousSection />
        <LevelsSection />
        <ScheduleSection />
      </div>
    </RecoilRoot>
  );
};

export default SponsorPage;
